import React, { useState } from 'react';
import './Authentication.css';
import { Link } from 'react-router-dom';

const Authentication = ({ onSignIn }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSignIn = () => {
    if(email && password) {
      onSignIn(email, password);
    }
  };

  return (
    <div className="auth-container">
      <h2>Sign In</h2>
      <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" className="auth-input" />
      <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" className="auth-input" />
      <button onClick={handleSignIn} className="auth-btn">Sign In</button>
      <Link to="/signup" className="auth-link">Don't have an account? Sign Up</Link>
    </div>
  );
};

export default Authentication;
