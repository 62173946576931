// src/components/NewsFeed.js
import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { Link, useLocation } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNews } from '../contexts/NewsContext';
import logo from '../handsup.png';
import { getColorForString } from '../helpers/helpers';
import debounce from 'lodash.debounce';

const NewsFeed = ({ user, newsItems, setNews, page, setPage, hasMore, setHasMore, searchTerm, setSearchTerm, tagFilter, setTagFilter, tg, setTg, selectedCategory, setSelectedCategory, source, setSource, handleCategoryChange, fetchNews }) => {


  const [scrollPosition, setScrollPosition] = useState(0);

  // Effect to save scroll position on unmount and restore on mount
  useEffect(() => {
    console.log("scroll effect", scrollPosition);
    // Restore scroll position on mount
    window.scrollTo(0, scrollPosition);

    return () => {
      console.log("scroll effect unmount", window.scrollY);
      // Save scroll position on unmount
      setScrollPosition(window.scrollY);
    };
  }, []);

  const handleTagClick = ((tagName) => {
    if (tagFilter === `&tag=${tagName}`) {
      setTagFilter("")
      setTg(null)
    } else {
      setTagFilter(`&tag=${tagName}`);
      setTg(tagName)
    }
  });

  const handleSearchChange = (event) => {
    const value = event.target.value;
    debounce(() => {
      setSearchTerm(value);
    }, 500);
  };

  const formatTextIntoParagraphs = (text) => {
    if (typeof text !== 'string') {
      throw new Error('Input must be a string');
    }

    // Split text into sentences
    const sentences = text.split(/(?<=[.!?])\s+/);

    // Group sentences into paragraphs
    let paragraphs = [];
    let currentParagraph = '';
    sentences.forEach((sentence, index) => {
      currentParagraph += sentence + ' ';

      // A simple rule to break into a new paragraph after every 5 sentences
      // or if it's the last sentence.
      if ((index + 1) % 5 === 0 || index === sentences.length - 1) {
        paragraphs.push(currentParagraph.trim());
        currentParagraph = '';
      }
    });

    // Wrap each paragraph in <p> tags and join them into a single HTML string
    return paragraphs.map(paragraph => `<p>${paragraph
      }</p>`).join('\n');
  }

  const getColorItemString = (value) => {
    let sum = 0;
    for (let i = 0; i < value.length; i++) {
      sum += value.charCodeAt(i);
    }

    const lightness = (sum % 11) + 20; // Generates a value between 20 and 30
    return `hsl(0, 0%, ${lightness}%)`;
  };

  // console.log(newsItems);
  // Render the news items here
  return (
    <div>

      <InfiniteScroll
        dataLength={newsItems.length}
        next={fetchNews}
        hasMore={hasMore}
        loader={<h4>Loading...</h4>}
        endMessage={
          <p style={{ textAlign: 'center' }}>
            <b>Yay! You have seen it all</b>
          </p>
        }
      // height={800}
      >

        {/*<div className="search-term-container">
          {tg &&
            <span onClick={(e) => {
              e.preventDefault();
              handleTagClick(tg);
            }} className='newsItem-tags-span' style={{ backgroundColor: getColorForString(tg) }}>
              #{tg}&nbsp;<span style={{ color: "black" }}>x</span>
            </span>
          }
        </div>*/}

        {newsItems.map((newsItem, i) => (
          <div key={newsItem.id} className='newsItem' style={{ backgroundColor: getColorItemString(newsItem.title) }}>
            <Link to={`/news/${newsItem.id}`} className="newsItem-link">
              <div className="newsItem-header">
                <img src={newsItem.image_url.startsWith("/") ? logo : newsItem.image_url} alt={newsItem.title} className="newsItem-image" />
                <div className="newsItem-content">
                  <span className="newsItem-title">{i + 1}. </span><span className="newsItem-title">{newsItem.title}</span>
                </div>
              </div>
              <div className="newsItem-tags">
                {/*newsItem.tags.filter((e) => e.name.length > 2 && e.name.length < 25).map((tag, index) => (
                  <span onClick={(e) => {
                    e.preventDefault();
                    handleTagClick(tag.name);
                  }} className='newsItem-tags-span' key={index} style={{ backgroundColor: getColorForString(tag.name) }}>
                    #{tag.name}
                  </span>
                ))*/}
              </div>
            </Link>
            <div className="newsItem-footer" style={{ fontSize: "75%" }}>
              <div className="newsItem-actions">
                <span className="newsItem-timestamp">{new Date(newsItem.created_at).toLocaleString()}</span>
                <div className="newsItem-action-buttons">
                  {/* <button className="newsItem-button newsItem-like-button"><i className="fas fa-thumbs-up"></i> <span className="newsItem-likes">{newsItem.likes_count}</span></button>
                  <button className="newsItem-button newsItem-bookmark-button"><i className="fas fa-eye"></i> <span className="newsItem-bookmarks">{newsItem.reads_count}</span></button> */}
                </div>
              </div>
              <div className="newsItem-actions">
                {/* <span className="newsItem-timestamp">{new Date(newsItem.created_at).toLocaleString()}</span> */}
                <div className="newsItem-action-buttons">
                  <button className="newsItem-button newsItem-like-button"><i className="fas fa-thumbs-up"></i> <span className="newsItem-likes">{newsItem.likes_count}</span></button>
                  <button className="newsItem-button newsItem-bookmark-button"><i className="fas fa-eye"></i> <span className="newsItem-bookmarks">{newsItem.reads_count}</span></button>
                  <button className="newsItem-button newsItem-comment-button"><i className="fas fa-comments"></i> <span className="newsItem-comments">0</span></button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </InfiniteScroll>
    </div>
  );
};

export default NewsFeed;