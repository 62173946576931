import React, { useState, lazy, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import axios from 'axios';
import './App.css';
import logo from './handsup.png';
import NewsFeed from './components/NewsFeed';
import NewsItemDetail from './components/NewsItemDetail';
import Authentication from './components/Authentication';
import { NewsProvider, useNews } from './contexts/NewsContext';
import SignUp from './components/SignUp';
import HamburgerIcon from './components/HamburgerIcon';
import UserIcon from './components/UserIcon';
import debounce from 'lodash.debounce';

const RightSidebar = ({ isRightSidebarOpen, setRightSidebarOpen, handleCategoryChange, handleSearchChange, searchTerm }) => {


  return (
    <div className="right-sidebar" onClick={() => { setRightSidebarOpen(!isRightSidebarOpen); }}>
      <HamburgerIcon />
      {isRightSidebarOpen && (
        <div className="overlay">
          <ul className="category-list">
          </ul>

          <hr />
          <div className="category-container">

          </div>
          <div className="category-list">
            <div className="" style={{ marginTop: "", marginLeft: "50px" }}>
              <input style={{ width: "70%" }}
                type="text"
                className="search-input"
                placeholder="Search..."
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </div>
            <ul>
              <li><Link to={`/`} >Latest</Link></li>
              <li><Link to={`/popular`} >Popular</Link></li>
              <li><Link to={`/reads`} >Most Read</Link></li>
              <li>&nbsp;</li>
              <li onClick={() => handleCategoryChange("")}>All</li>
              <li onClick={() => handleCategoryChange("Vanguard Nigeria")}>Vanguard Nigeria</li>
              <li onClick={() => handleCategoryChange("Punch Nigeria")}>Punch Nigeria</li>
              <li onClick={() => handleCategoryChange("Channels TV")}>Channels TV</li>
              <li onClick={() => handleCategoryChange("ThisDay Nigeria")}>ThisDay Nigeria</li>
              <li onClick={() => handleCategoryChange("Guardian Nigeria")}>Guardian Nigeria</li>
              <li onClick={() => handleCategoryChange("Nairametrics")}>Nairametrics</li>
              <li onClick={() => handleCategoryChange("Premium Times Nigeria")}>Premium Times Nigeria</li>
              <li onClick={() => handleCategoryChange("The Nation Nigeria")}>The Nation Nigeria</li>
              <li onClick={() => handleCategoryChange("Le Faso - Burkina Faso")}>Le Faso - Burkina Faso</li>
              <li onClick={() => handleCategoryChange("Omega Médias - Burkina Faso")}>Omega Médias - Burkina Faso</li>
              <li onClick={() => handleCategoryChange("L'Observateur - Burkina Faso")}>L'Observateur - Burkina Faso</li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

const LeftSidebar = ({ isLeftSidebarOpen, setLeftSidebarOpen }) => {
  return (
    <div className="left-sidebar" onClick={() => { setLeftSidebarOpen(!isLeftSidebarOpen); }}>
      <UserIcon />
      {isLeftSidebarOpen && (
        <div className="overlay">
          <Link to="/login" className="nav-link">Login</Link>
          <Link to="/signup" className="nav-link">Sign Up</Link>
        </div>
      )}
    </div>
  );
};

function App() {
  const [user, setUser] = useState(null);
  const [isRightSidebarOpen, setRightSidebarOpen] = useState(false);
  const [isLeftSidebarOpen, setLeftSidebarOpen] = useState(false);
  // states needed by newsfeed component
  const { news: newsItems, setNews } = useNews();
  // const [newsItems, setNewsItems] = useState([]);
  const [page, setPage] = useState(2);
  const [hasMore, setHasMore] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [tagFilter, setTagFilter] = useState("");
  const [tg, setTg] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [source, setSource] = useState("")

  useEffect(() => {
    console.log("App init...");
    document.title = "Oya News!";
    // Fetch the first page of news data
    axios.get(`https://oya.naija.dev/newslinks/latest?page=1&per_page=20${tagFilter}${source}&search=${searchTerm}`)
      .then(response => {
        setNews(response.data);
      });
    // Poll for new news items every few minutes (e.g., 5 minutes)
    const intervalId = setInterval(function () {
      try {
        console.log("checking for updates... ")
        axios.get(`https://oya.naija.dev/newslinks/latest?page=${1}&per_page=5${tagFilter}${source}&search=${searchTerm}`)
          .then(response => {
            if (response.data.length > 0) {
              setNews(prevNewsItems => {
                let kys = prevNewsItems.map(item => item.id);
                const mergedItems = [
                  ...response.data.filter(newItem => !kys.includes(newItem.id)),
                  ...prevNewsItems
                ];
                mergedItems.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
                return mergedItems;
              });
            }
          });
      } catch (error) {
        console.error(error);
        console.log("Error updating feed... will try again in a bit!")
      }
    }, 1 * 60 * 1000);
    // Clear interval on component unmount
    return () => clearInterval(intervalId);
  }, [setNews, tagFilter, selectedCategory, searchTerm]);

  const fetchNews = useCallback(() => {
    axios.get(`https://oya.naija.dev/newslinks/latest?page=${page}&per_page=20${tagFilter}${source}&search=${searchTerm}`)
      .then(response => {
        if (response.data.length > 0) {
          setNews(prevNewsItems => {
            // Merge new items
            const mergedItems = [
              ...prevNewsItems,
              ...response.data.filter(newItem => !prevNewsItems.some(prevItem => prevItem.id === newItem.id))
            ];
            return mergedItems.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
          });
          setPage(prevPage => prevPage + 1);
        } else {
          setHasMore(false);
        }
      });
  }, [page, setNews, tagFilter, selectedCategory, searchTerm]);

  const handleSearchChange = (event) => {
    console.log(event);
    const value = event.target.value;
    // debounce(() => {
    setSearchTerm(value);
    // }, 500);
  };

  const handleCategoryChange = (event) => {
    setSource(`&source=${event.target.value}`);
    setSelectedCategory(event.target.value);
  };

  const handleSignIn = (email, password) => {
    axios.post('http://oya.naija.dev/api/login', {
      user: {
        email,
        password,
      },
    })
      .then(response => {
        const { jwt } = response.data;
        localStorage.setItem('jwt', jwt);
        setUser({ email });
      })
      .catch(error => {
        console.error('Error signing in', error);
      });
  };

  const handleSignUp = (email, password, confirmPassword) => {
    axios.post('http://oya.naija.dev/api/signup', { email, password, confirmPassword })
      .then(response => {
        const { jwt } = response.data;
        localStorage.setItem('jwt', jwt);
        setUser({ email });
      })
      .catch(error => {
        console.error('Error signing up', error);
      });
  };

  return (
    // <Router>
    // <NewsProvider>
    <div className="app">
      <div className="top-bar">
        <LeftSidebar isLeftSidebarOpen={isLeftSidebarOpen} setLeftSidebarOpen={setLeftSidebarOpen} />
        <h3 className="top-bar-title" style={{ justifyContent: "center", alignContent: "center", display: "inherit" }}><Link to="/" className='nav-link' style={{ justifyContent: "center", alignContent: "center", display: "inherit" }}><span style={{ alignItems: "center", display: "inherit", justifyContent: "center" }}><img src={logo} height={60} />Oya News!</span></Link></h3>
        <RightSidebar isRightSidebarOpen={isRightSidebarOpen} setRightSidebarOpen={setRightSidebarOpen} handleSearchChange={handleSearchChange} searchTerm={searchTerm} />

      </div>
      <div className="container">
        <Routes>
          <Route path="/" exact element={<NewsFeed user={user} newsItems={newsItems} setNews={setNews} page={page} setPage={setPage} hasMore={hasMore} setHasMore={setHasMore} searchTerm={searchTerm} setSearchTerm={setSearchTerm} tagFilter={tagFilter} setTagFilter={setTagFilter} tg={tg} setTg={setTg} selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} source={source} setSource={setSource} fetchNews={fetchNews} />} />
          <Route path="/news/:id" element={<NewsItemDetail />} />
          <Route path="/login" element={<Authentication onSignIn={handleSignIn} />} />
          <Route path="/signup" element={<SignUp onSignUp={handleSignUp} />} />
          <Route path="/popular" element={<NewsFeed user={user} newsItems={newsItems} setNews={setNews} page={page} setPage={setPage} hasMore={hasMore} setHasMore={setHasMore} searchTerm={searchTerm} setSearchTerm={setSearchTerm} tagFilter={tagFilter} setTagFilter={setTagFilter} tg={tg} setTg={setTg} selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} source={source} setSource={setSource} fetchNews={fetchNews} />} />
          <Route path="/reads" element={<NewsFeed user={user} newsItems={newsItems} setNews={setNews} page={page} setPage={setPage} hasMore={hasMore} setHasMore={setHasMore} searchTerm={searchTerm} setSearchTerm={setSearchTerm} tagFilter={tagFilter} setTagFilter={setTagFilter} tg={tg} setTg={setTg} selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} source={source} setSource={setSource} fetchNews={fetchNews} />} />
        </Routes>
      </div>
    </div>
    //   </NewsProvider>
    // </Router>
  );
}

export default App;
