// helpers.js
import { v4 as uuidv4 } from 'uuid';
// import axios from 'axios';

export const getDeviceId = () => {
  let deviceId = localStorage.getItem('device_uuid');
  if (!deviceId) {
    deviceId = generateUUID();
    localStorage.setItem('device_uuid', deviceId);
  }
  return deviceId;
};

export const generateUUID = () => {
  return uuidv4();
};

export const getColorForString = (input) => {
  const colors = [
    '#F44336', '#E91E63', '#9C27B0', '#673AB7', '#3F51B5',
    '#2196F3', '#03A9F4', '#00BCD4', '#009688', '#4CAF50',
    '#8BC34A', '#FFC107', '#FF9800',
    '#FF5722', '#795548', '#9E9E9E', '#607D8B'
  ];

  let hash = 0;
  for (let i = 0; i < input.length; i++) {
    hash = input.charCodeAt(i) + ((hash << 5) - hash);
  }

  const index = Math.abs(hash) % colors.length;
  return colors[index];
};

