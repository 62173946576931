import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate, Link } from 'react-router-dom';
// import { useNews } from '../contexts/NewsContext';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import './NewsItemDetail.css';
import { getColorForString, getDeviceId } from '../helpers/helpers';

const NewsItemDetail = () => {
  const { id } = useParams();
  const [newsItem, setNewsItem] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!newsItem) {
      // console.log("fetching ", id);
      axios.get(`https://oya.naija.dev/newslinks/${id}?device_uuid=${getDeviceId()}`)
        .then(response => {
          setNewsItem(response.data);
          document.title = response.data.title;
          // console.log("set news item", getDeviceId());
        })
        .catch(error => {
          console.error('Failed to fetch news item', error);
        });
      return () => {
        document.title = "Oya News!";
      };
    }
  }, []);

  if (!newsItem) {
    return <div>Loading...</div>;
  }

  const handleLike = () => {
    if (!localStorage.getItem('jwt')) {
      navigate('/login');
      return;
    }
    // handle like action
  };

  const formatTextIntoParagraphs = (text) => {
    if (typeof text !== 'string') {
      throw new Error('Input must be a string');
    }

    // Split text into sentences
    const sentences = text.split(/(?<=[.!?])\s+/);

    // Group sentences into paragraphs
    let paragraphs = [];
    let currentParagraph = '';
    sentences.forEach((sentence, index) => {
      currentParagraph += sentence + ' ';

      // A simple rule to break into a new paragraph after every 5 sentences
      // or if it's the last sentence.
      if ((index + 1) % 5 === 0 || index === sentences.length - 1) {
        paragraphs.push(currentParagraph.trim());
        currentParagraph = '';
      }
    });

    // Wrap each paragraph in <p> tags and join them into a single HTML string
    return paragraphs.map(paragraph => `<p>${paragraph
      }</p>`).join('\n');
  }

  const handleBookmark = () => {
    if (!localStorage.getItem('jwt')) {
      navigate('/auth');
      return;
    }
    // handle bookmark action
  };

  // console.log(newsItem);
  return (
    <div className="news-item-detail">
      <div className="news-item-detail-header">
        {/* Adding the back button */}
        <h3>
          <Link to="/" onClick={(e) => { e.preventDefault(); navigate(-1) }} className="back-button">
            <i className="fas fa-arrow-left"></i>
          </Link>{newsItem.title}</h3>
      </div>
      <Tabs>
        <TabList>
          <Tab>Summary</Tab>
          <Tab>Reader</Tab>
          <Tab>Comments</Tab>
        </TabList>

        <TabPanel>

          {newsItem.image_url.startsWith("/") ? null : <img src={newsItem.image_url} alt={newsItem.title} />}
          <div> <b><i><u>{new Date(newsItem.created_at).toLocaleString()}</u></i></b></div>
          <div dangerouslySetInnerHTML={{ __html: newsItem.summary }} />
          <div>
            <button className="button like" onClick={handleLike}>
              <i className="fas fa-thumbs-up icon"></i>
              &nbsp;Like
            </button>
            <button className="button bookmark" onClick={handleBookmark}>
              <i className="fas fa-bookmark icon"></i>
              &nbsp;Bookmark
            </button>
          </div>
          <div className="newsItem-tags">
            {newsItem.tags && newsItem.tags.filter((e) => e.name.length > 2 && e.name.length < 25).map((tag, index) => (
              <span key={index} style={{ backgroundColor: getColorForString(tag.name), padding: '3px 6px', fontSize: '0.8em', marginRight: '4px', borderRadius: '2px' }}>
                #{tag.name}
              </span>
            ))}
          </div> 
        </TabPanel>

        <TabPanel>
          {newsItem.image_url.startsWith("/") ? null : <img src={newsItem.image_url} alt={newsItem.title} />}
          <div> <b><i><u>{new Date(newsItem.created_at).toLocaleString()}</u></i></b></div>
          {/* <div dangerouslySetInnerHTML={{ __html: formatTextIntoParagraphs(newsItem.reader) }} /> */}
          <div dangerouslySetInnerHTML={{ __html: newsItem.reader }} />
          {/* <p>Source: {newsItem.news_source}</p>
          <p>Likes: {newsItem.likes_count}</p>
          <p>Reads: {newsItem.reads_count}</p> */}
          <div className="newsItem-actions">
            {/* <span className="newsItem-timestamp">{new Date(newsItem.created_at).toLocaleString()}</span> */}
            <div className="newsItem-action-buttons">
              <button className="newsItem-button newsItem-like-button"><i className="fas fa-thumbs-up"></i> <span className="newsItem-likes">{newsItem.likes_count}</span></button>
              <button className="newsItem-button newsItem-bookmark-button"><i className="fas fa-eye"></i> <span className="newsItem-bookmarks">{newsItem.reads_count}</span></button>
              <button className="newsItem-button newsItem-comment-button"><i className="fas fa-comments"></i> <span className="newsItem-comments">0</span></button>
              <button className="newsItem-button newsItem-bookmark-button"><span className="newsItem-comments"><strong>{newsItem.news_source}</strong></span></button>
            </div>
          </div>
          <div>
            <button className="button like" onClick={handleLike}>
              <i className="fas fa-thumbs-up icon"></i>
              &nbsp;Like
            </button>
            <button className="button bookmark" onClick={handleBookmark}>
              <i className="fas fa-bookmark icon"></i>
              &nbsp;Bookmark
            </button>
          </div>
        </TabPanel>

        <TabPanel>
          <p>Comments will be displayed here.</p>
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default NewsItemDetail;
