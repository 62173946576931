import React, { useState } from 'react';
import './Authentication.css';
import { Link } from 'react-router-dom';

const SignUp = ({ onSignUp }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordMatch, setPasswordMatch] = useState(true);

  const handleSignUp = () => {
    if(email && password && confirmPassword) {
      if(password === confirmPassword){
        setPasswordMatch(true);
        onSignUp(email, password, confirmPassword);
      } else {
        setPasswordMatch(false);
      }
    }
  };

  return (
    <div className="auth-container">
      <h2>Sign Up</h2>
      <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" className="auth-input" />
      <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" className="auth-input" />
      <input type="password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} placeholder="Confirm Password" className="auth-input" />
      {!passwordMatch && <p>Passwords do not match</p>}
      <button onClick={handleSignUp} className="auth-btn">Sign Up</button>
      <Link to="/login" className="auth-link">Already have an account? Log In</Link>
    </div>
  );
};

export default SignUp;
